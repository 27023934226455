import styled, { css } from 'styled-components';
import { ButtonReset, Stack } from '@tymate/margaret';

export const theme = {
  background: '#f2f2f2',
  primary: '#a91a1d',
  primaryLight: '#bf5e60',
  buttonBorderRadius: '8px',
  borderButtonRadius: '8px',
};

export const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  width: 100%;

  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
  }
`;

export const EditorContainer = styled.div`
  position: absolute;
  bottom: 24px;
  z-index: 2;
  left: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #fff;
  transform: translateX(-50%);
`;

export const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

export const ListWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

export const MapWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 300px;
  bottom: 0;
  right: 0;
  background: #eee
    url('data:image/svg+xml,\
      <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".08" >\
      <rect x="200" width="200" height="200" />\
      <rect y="200" width="200" height="200" />\
      </svg>\
    ');
  background-size: 30px 30px;
`;

export const Climate = styled(ButtonReset)`
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  width: 100%;
  text-align: left;

  ${({ isActive }) =>
    isActive &&
    css`
      box-shadow: inset 4px 0 0 0 ${({ theme }) => theme.primary};
    `}
`;

export const Card = styled(Stack)`
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 2px;

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      box-shadow: none;
    `}
`;

export const CardTitle = styled.h2`
  margin-top: 0;
`;

export const DownloadButton = styled(ButtonReset)`
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  z-index: 3;
  color: #666;
  border-radius: 2;

  &:hover {
    color: #333;
  }
`;
