import React, {
  memo,
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {
  GoogleMap,
  LoadScript,
  GroundOverlay,
  DrawingManager,
} from '@react-google-maps/api';
import { useWindowSize } from 'react-use';
import { Stack, Button, ButtonReset } from '@tymate/margaret';
import {
  Input,
  EditorContainer,
  Page,
  ListWrapper,
  MapWrapper,
  Climate,
  Card,
  CardTitle,
  DownloadButton,
} from './ui';
import {
  MdFileDownload,
  MdHighlightOff,
  MdSearch,
  MdDelete,
} from 'react-icons/md';
import Climates from './Climates';
import { AppContext } from './AppProvider';
import { FixedSizeList } from 'react-window';
import logo from './logo.png';

const LIBRARIES = ['drawing'];

const Logo = styled.img`
  display: block;
  width: 100%;
  height: 70px;
`;

const Title = styled(CardTitle)`
  font-family: AJensonPro, sans-serif;
  margin-bottom: 0;
`;

const SearchWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing()};
  background-color: ${({ theme }) => theme.background};
  border-radius: 999em;
  position: relative;

  ${Input} {
    box-shadow: none;
    border: 0;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    height: 36px;
    padding-left: ${({ theme }) => theme.spacing(3)};
    position: relative;
    z-index: 1;

    ${({ value, theme }) =>
      Boolean(value) &&
      `
        padding-right: ${theme.spacing(3)};
      `}
  }
`;

const SearchIcon = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.spacing()};
  display: flex;
  font-size: 20px;

  ${({ variant, theme }) =>
    variant === 'clear' &&
    `
      left: auto;
      right: ${theme.spacing()};
      z-index: 2;
    `}
`;

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 0.5,
  lng: 0.75,
};

const App = () => {
  const [map, setMap] = useState(null);
  const [groundBounds, setGroundBounds] = useState(null);
  const [newClimate, setNewClimate] = useState();
  const [newClimateName, setNewClimateName] = useState('');

  const {
    climates,
    onEditClimate,
    editedClimateIndex,
    onAddClimate,
    climatesFileName,
    imageUrl,
    imageFileName,
    onUploadPlist,
    onResetPlist,
    onUploadImage,
    onResetImage,
    onDownload,
    search,
    setSearch,
  } = useContext(AppContext);

  const { height } = useWindowSize();
  const listRef = useRef();

  const onLoad = useCallback(map => {
    const southWest = new window.google.maps.LatLng(0, 0);
    const northEast = new window.google.maps.LatLng(1.5, 1);
    const groundBounds = new window.google.maps.LatLngBounds();
    groundBounds.extend(southWest);
    groundBounds.extend(northEast);

    map.fitBounds(groundBounds);
    setMap(map);
    setGroundBounds(groundBounds);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  const handlePolygonComplete = polygon => {
    setNewClimate(polygon);
  };

  const handleCancelAddClimate = () => {
    newClimate.setMap(null);
    setNewClimate(null);
  };

  const handleConfirmAddClimate = () => {
    newClimate.setMap(null);
    onAddClimate({ polygon: newClimate, name: newClimateName });
    setNewClimate(null);
  };

  useEffect(() => {
    if (Number(editedClimateIndex) !== editedClimateIndex) {
      return;
    }

    listRef.current.scrollToItem(editedClimateIndex);
  }, [editedClimateIndex]);

  if (!climates || !imageUrl) {
    return (
      <Page>
        <ListWrapper style={{ height: '100vh' }}>
          <Logo src={logo} alt="ClimaVinea" />
          <Stack
            style={{ height: '100%', paddingBottom: 70 + 36 + 32 }}
            size="full"
            alignX="center"
            alignY="center"
          >
            <Card
              direction="column"
              variant="nested"
              style={{ width: '100%' }}
              gutterSize={0.5}
            >
              <Title>Fichier plist</Title>

              <Button as="label" variant="primary">
                Charger un plist
                <input
                  style={{ display: 'none' }}
                  type="file"
                  onChange={onUploadPlist}
                  accept=".plist"
                />
              </Button>

              {Boolean(climates) && (
                <Stack gutterSize={0.5} alignY="center">
                  <div>{climatesFileName}</div>
                  <ButtonReset onClick={onResetPlist}>
                    <MdDelete />
                  </ButtonReset>
                </Stack>
              )}
            </Card>
          </Stack>
        </ListWrapper>
        <MapWrapper>
          <Stack
            style={{ height: '100%' }}
            size="full"
            alignX="center"
            alignY="center"
          >
            <Card direction="column" gutterSize={0.5}>
              <Title>Fond de carte</Title>
              <Button as="label" variant="primary">
                Charger le fond de carte
                <input
                  style={{ display: 'none' }}
                  type="file"
                  onChange={onUploadImage}
                  accept="image/*"
                />
              </Button>
              {Boolean(imageUrl) && (
                <Stack gutterSize={0.5} alignY="center">
                  <div>{imageFileName}</div>
                  <ButtonReset onClick={onResetImage}>
                    <MdDelete />
                  </ButtonReset>
                </Stack>
              )}
            </Card>
          </Stack>
        </MapWrapper>
      </Page>
    );
  }

  return (
    <Page>
      <ListWrapper>
        <Logo src={logo} alt="ClimaVinea" />
        <SearchWrapper>
          <SearchIcon>
            <MdSearch />
          </SearchIcon>
          <Input
            placeholder="Rechercher"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          {Boolean(search) && (
            <SearchIcon
              variant="clear"
              as={ButtonReset}
              onClick={() => setSearch('')}
            >
              <MdHighlightOff />
            </SearchIcon>
          )}
        </SearchWrapper>
        <FixedSizeList
          ref={listRef}
          height={height - (70 + 36 + 32)}
          itemCount={climates.length}
          itemSize={51}
          width={300}
        >
          {({ index, style }) => (
            <Climate
              style={style}
              isActive={index === editedClimateIndex}
              onClick={() => onEditClimate(index)}
            >
              {climates[index]?.Nom}
            </Climate>
          )}
        </FixedSizeList>
      </ListWrapper>

      <MapWrapper>
        <LoadScript
          googleMapsApiKey="AIzaSyCCXqu9Fkw4sCZ_yQMybpRuXx8R8BAxBwM"
          libraries={LIBRARIES}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            center={center}
            options={{
              fullscreenControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              restriction: {
                latLngBounds: groundBounds,
                strictBounds: true,
              },
            }}
          >
            {Boolean(groundBounds) && (
              <GroundOverlay
                url={imageUrl}
                bounds={groundBounds}
                style={{ zIndex: 2 }}
              />
            )}

            <Climates map={map} />

            <DownloadButton onClick={onDownload}>
              <MdFileDownload size={24} />
            </DownloadButton>

            {!editedClimateIndex && (
              <DrawingManager
                onPolygonComplete={handlePolygonComplete}
                options={{
                  drawingControlOptions: {
                    drawingModes: ['polygon'],
                  },
                  polygonOptions: {
                    strokeWeight: 2,
                    strokeColor: 'hsl(137, 54%, 61%)',
                    fillColor: 'hsla(137, 54%, 61%)',
                    cursor: 'normal',
                  },
                }}
              />
            )}

            {Boolean(newClimate) && (
              <EditorContainer>
                <Stack
                  size="full"
                  direction="column"
                  gutterSize={1}
                  padding={1}
                >
                  <Input
                    placeholder="Nom du climat"
                    type="text"
                    value={newClimateName}
                    onChange={e => setNewClimateName(e.target.value)}
                  />
                  <Stack size="full" alignX="center" gutterSize={1}>
                    <Button onClick={handleCancelAddClimate}>Annuler</Button>
                    <Button
                      onClick={handleConfirmAddClimate}
                      disabled={!newClimateName}
                      variant="primary"
                    >
                      Enregistrer
                    </Button>
                  </Stack>
                </Stack>
              </EditorContainer>
            )}
          </GoogleMap>
        </LoadScript>
      </MapWrapper>
    </Page>
  );
};

export default memo(App);
