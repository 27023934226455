import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { MargaretProvider } from '@tymate/margaret';
import { theme } from './ui';
import 'sanitize.css/sanitize.css';
import 'sanitize.css/typography.css';
import AppProvider from './AppProvider';

ReactDOM.render(
  <React.StrictMode>
    <MargaretProvider theme={theme}>
      <AppProvider>
        <App />
      </AppProvider>
    </MargaretProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
