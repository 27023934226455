import React, { createContext, useState } from 'react';
import plist from 'plist';
import fileSaver from 'file-saver';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AJensonPro';
    src: url('./AJensonPro-Regular.woff') format('woff');
  }
`;

export const AppContext = createContext();

const HEIGHT_TO_WIDTH_RATIO = 1.5;

const coordinatesToLatLng = ({ x, y }) => ({
  lat: HEIGHT_TO_WIDTH_RATIO - HEIGHT_TO_WIDTH_RATIO * y,
  lng: x,
});

const latLngToCoordinates = ({ lat, lng }) => ({
  x: lng,
  y: (HEIGHT_TO_WIDTH_RATIO - lat) / HEIGHT_TO_WIDTH_RATIO,
});

const formatClimates = climates =>
  climates.map(climate => ({
    ...climate,
    Points: climate.Points.map((coordinates, index) => {
      const [x, y] = coordinates
        .replace('{', '')
        .replace('}', '')
        .split(', ')
        .map(Number);

      return coordinatesToLatLng({ x, y });
    }),
  }));

const formatPlist = climates =>
  climates.map(climate => ({
    ...climate,
    Points: climate.Points.map(({ lat, lng }, index) => {
      const { x, y } = latLngToCoordinates({ lat, lng });
      return `{${x}, ${y}}`;
    }),
  }));

const AppProvider = ({ children }) => {
  const [imageUrl, setImageUrl] = useState();
  const [imageFileName, setImageFileName] = useState('');
  const [climates, setClimates] = useState();
  const [climatesFileName, setClimatesFileName] = useState();
  const [editedClimateIndex, setEditedClimateIndex] = useState();
  const [editedClimateName, setEditedClimateName] = useState('');
  const [search, setSearch] = useState('');

  const handleUploadPlist = e => {
    const [plistFile] = e.target.files;

    const reader = new FileReader();
    reader.readAsText(plistFile, 'UTF-8');
    reader.onload = e => {
      setClimates(formatClimates(plist.parse(e.target.result)));
      setClimatesFileName(plistFile.name);
    };
  };

  const handleResetPlist = () => {
    setClimates();
    setClimatesFileName();
  };

  const handleUploadImage = e => {
    const [imageFile] = e.target.files;

    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = e => {
      setImageFileName(imageFile.name);
      setImageUrl(e.target.result);
    };
  };

  const handleResetImage = () => {
    setImageUrl();
    setImageFileName();
  };

  const handleUpdateClimatePaths = paths => {
    setClimates(
      climates.map((climate, index) => {
        if (editedClimateIndex === index) {
          return {
            ...climate,
            Points: paths,
          };
        }

        return climate;
      }),
    );
    setEditedClimateIndex(null);
    setEditedClimateName('');
  };

  const handleEditClimate = index => {
    setEditedClimateIndex(index);
    setEditedClimateName(climates?.[index]?.Nom ?? '');
  };

  const handleAddClimate = ({ polygon, name }) => {
    setClimates([
      ...climates,
      {
        id: name,
        Nom: name,
        Coordonnees: 'ios',
        Points: polygon
          .getPath()
          .getArray()
          .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })),
      },
    ]);
  };

  const handleDownload = () => {
    const blob = new Blob([plist.build(formatPlist(climates))], {
      type: 'application/xml',
    });
    fileSaver.saveAs(blob, 'climats.plist');
  };

  const filteredClimates =
    Boolean(climates) &&
    climates.filter(({ Nom }) =>
      Nom.toLowerCase().includes(search.toLowerCase()),
    );

  return (
    <AppContext.Provider
      value={{
        climates: filteredClimates,
        editedClimateIndex,
        editedClimateName,
        climatesFileName,
        imageUrl,
        imageFileName,
        onResetPlist: handleResetPlist,
        onResetImage: handleResetImage,
        onUploadPlist: handleUploadPlist,
        onUploadImage: handleUploadImage,
        onChangeEditedClimateName: setEditedClimateName,
        onUpdateClimatePaths: handleUpdateClimatePaths,
        onEditClimate: handleEditClimate,
        onAddClimate: handleAddClimate,
        onDownload: handleDownload,
        search,
        setSearch,
      }}
    >
      <>
        <GlobalStyle />
        {children}
      </>
    </AppContext.Provider>
  );
};

export default AppProvider;
