import React, { useRef, useContext, useEffect } from 'react';
import { Polygon } from '@react-google-maps/api';
import { Stack, Button } from '@tymate/margaret';
import { AppContext } from './AppProvider';
import { EditorContainer, Input } from './ui';

const Climates = ({ map }) => {
  const {
    editedClimateIndex,
    onEditClimate,
    climates,
    onUpdateClimatePaths,
    editedClimateName,
    onChangeEditedClimateName,
  } = useContext(AppContext);

  const polygonsRef = useRef([]);

  const handleFinishEdition = () => {
    const paths = polygonsRef.current[editedClimateIndex]
      .getPath()
      .getArray()
      .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

    onUpdateClimatePaths(paths);
  };

  const handleCancelEdition = () => {
    polygonsRef.current[editedClimateIndex].setPaths(
      climates[editedClimateIndex].Points,
    );
    onEditClimate(null);
  };

  const handleLoadPolygon = ({ polygon, index }) => {
    polygonsRef.current[index] = polygon;
  };

  useEffect(() => {
    const targetPolygon = polygonsRef.current?.[editedClimateIndex];

    if (!Boolean(targetPolygon)) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    const paths = targetPolygon
      .getPath()
      .getArray()
      .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
    paths.forEach(point => {
      bounds.extend(point);
    });

    map.fitBounds(bounds);
  }, [map, editedClimateIndex]);

  return (
    <>
      {climates.map((climate, index) => (
        <Polygon
          onClick={async () => {
            if (Number(editedClimateIndex) === editedClimateIndex) {
              handleCancelEdition();
            }
            setTimeout(() => {
              onEditClimate(index);
            });
          }}
          draggable={index === editedClimateIndex}
          editable={index === editedClimateIndex}
          onLoad={polygon => handleLoadPolygon({ polygon, index })}
          paths={climate.Points}
          options={{
            strokeWeight: 2,
            strokeColor: '#a91a1d',
            fillColor: '#a91a1d',
            cursor: 'normal',
          }}
        />
      ))}

      {Number(editedClimateIndex) === editedClimateIndex && (
        <EditorContainer>
          <Stack size="full" direction="column" gutterSize={1} padding={1}>
            <Input
              placeholder="Nom du climat"
              type="text"
              value={editedClimateName}
              onChange={e => onChangeEditedClimateName(e.target.value)}
            />
            <Stack size="full" alignX="center" gutterSize={1}>
              <Button onClick={handleCancelEdition}>Annuler</Button>
              <Button onClick={handleFinishEdition} variant="primary">
                Enregistrer
              </Button>
            </Stack>
          </Stack>
        </EditorContainer>
      )}
    </>
  );
};

export default Climates;
